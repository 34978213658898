import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isNullish } from "@commonHelpers/math-utils";
import { INode } from '@interfaces/iNode';
import { CommunicationMode, SoftwarepartnerApiSessionDto } from '../interfaces/HttpClient/AccountApiPublicModels';
import { ISoftwarepartnerApiMessage } from '../models/softwarepartnerApi/ISoftwarepartnerApiMessage';
import { SoftwarepartnerApiInteractionType } from '../models/softwarepartnerApi/SoftwarepartnerApiInteractionType';
import { GlobalVarService } from './global-var.service';
import { SoftwarepartnerApiSelectionService } from './softwarepartner-api-selection.service';
import { SoftwarepartnerApiSessionService } from './softwarepartner-api-session.service';

@Injectable({
	providedIn: 'root'
})
export class SoftwarepartnerApiService {

	private apiSession: SoftwarepartnerApiSessionDto | null;
	private catalogItems: INode[];

	constructor(private globalVarService: GlobalVarService, private softwarepartnerApiSessionService: SoftwarepartnerApiSessionService, private softwarepartnerApiSelectionService: SoftwarepartnerApiSelectionService, private router: Router, private route: ActivatedRoute) {
	}

	public init() {
		this.softwarepartnerApiSessionService.getAsObservable().subscribe(async apiSession => {
			if (apiSession !== null && this.apiSession?.apiSessionId !== apiSession.apiSessionId) {
				this.apiSession = apiSession;
			}
		})
		this.softwarepartnerApiSelectionService.getAsObservable().subscribe(async catalogItems => {
			this.catalogItems = catalogItems;
		})

		if ((window as any)?.chrome?.webview) {
			(window as any).chrome.webview.addEventListener('message', (event: Event) => {
				this.handleMessage(event)
			});
		}
		const apiSessionId = new URLSearchParams(window.location.search).get("apiSessionId");
		if (!isNullish(apiSessionId)) {
			this.globalVarService.setSoftwarePartnerSession(apiSessionId);
		}
	}

	public handleMessage(message: any) {
		if (isNullish(this.apiSession)) { return; }

		switch (message.data) {
			case 'ade_export':
				this.postCatalogItemExport(SoftwarepartnerApiInteractionType.Button);
				break;
		}
	}

	public postCatalogItemExport(interactionType: SoftwarepartnerApiInteractionType) {
		const catalogItemGuids = this.catalogItems?.map(node => node.guid) ?? [];
		const message: ISoftwarepartnerApiMessage = {
			type: interactionType,
			catalogItemGuids
		};
		switch (this.apiSession?.communicationMode) {
			case CommunicationMode.PostMessageIFrame:
				return this.handleIframeExport(message);
			case CommunicationMode.UrlNavigation:
				return this.handleUrlNavigationExport(message);
			case CommunicationMode.PostMessageWebView:
				return this.handleWebViewExport(message);
		}
	}

	private handleIframeExport(message: ISoftwarepartnerApiMessage) {
		if (!this.apiSession?.postMessageTargetOrigin) {
			return;
		}
		if (window.top) {
			window.top.postMessage(message, this.apiSession?.postMessageTargetOrigin)
		}
	}

	private handleUrlNavigationExport(message: ISoftwarepartnerApiMessage) {
		void this.router.navigate(
			[],
			{
				relativeTo: this.route,
				queryParams: { catalogItemGuids: message.catalogItemGuids.join(","), interactionType: message.type },
				queryParamsHandling: 'merge'
			}).then(async () => {
				await this.router.navigate([], {
					queryParams: {
						'catalogItemGuids': null,
						'interactionType': null
					},
					queryParamsHandling: 'merge'
				});
			});
	}

	private handleWebViewExport(message: ISoftwarepartnerApiMessage) {
		if (!isNullish((window as any).chrome?.webview)) {
			(window as any).chrome.webview.postMessage(message)
		}
	}

	public hasSession() {
		return !isNullish(this.globalVarService.getSoftwarePartnerSession())
	}

	public async setSessionAsync(apiSessionId: string) {
		this.globalVarService.setSoftwarePartnerSession(apiSessionId);
		await this.softwarepartnerApiSessionService.setAsync(apiSessionId)
	}

	public getApiSessionId() {
		return this.apiSession?.apiSessionId;
	}

	public getApplicationKey() {
		return this.apiSession?.softwareId;
	}

	public setSelectedCatalogItems(markedNodes: INode[], currentNode: INode) {
		this.softwarepartnerApiSelectionService.setSelectedCatalogItems(markedNodes.length > 1 ? markedNodes : [currentNode]);
	}
}

